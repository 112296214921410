import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { Theme } from '@mui/material'

type Props = {
  fill?: boolean,
  unClickable?: boolean,
  overwriteRoot: string,
  overwriteClassName: string,
  onClick: () => void,
  children: JSX.Element | JSX.Element[] | string,
  invert?: boolean,
}

const COLORS = ['#ecba82', '#18ca92', '#1274e3', '#6e32e5', '#f3455c']
let counter = 0
const getColorByCounter = (): string => {
  counter += 1

  return COLORS[counter % COLORS.length]
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    textAlign: 'left',
    marginTop: theme.spacing(2),
  },
  wrapper: {
    cursor: 'pointer',
    border: '1px solid',
    borderColor: ({ invert }: { color: string, invert?: boolean }) => invert ? 'white' : '#111111',
    backgroundColor: ({ invert }: { color: string, invert?: boolean }) => invert ? '#111111' : 'white',
    borderRadius: '2px',
    display: 'inline-flex',
    flexDirection: 'row',
    height: '36px',
    outline: 'none',
    padding: '0',
    color: ({ invert }: { color: string, invert?: boolean }) => invert ? 'white' : '#333333',
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    alignItems: 'center',
    verticalAlign: 'middle',
    textDecoration: 'none',
    justifyContent: ' center',
    transitionDuration: '100ms',
    transitionProperty: 'background-color, border-color',
    transitionTimingFunction: 'ease-out',
    '&:hover': {
      borderColor: ({ color, invert }: { color: string, invert?: boolean }) => invert ? 'white' : color,
      color: ({ color, invert }: { color: string, invert?: boolean }) => invert ? 'white' : color,
      backgroundColor: ({ color, invert }: { color: string, invert?: boolean }) => invert ? color : 'white',
    },
  },
}), { name: 'Button' })

export default function Button(props: Props): JSX.Element {
  const [color, setColor] = useState(getColorByCounter())
  const classes = useStyles({ color, ...props })
  const style = props.fill ? {
    width: '100%',
  } : {}


  return (
    <div className={classNames(classes.root, props.overwriteRoot)} style={style} >
      <div
        className={classNames(classes.wrapper, props.overwriteClassName)}
        onClick={props.unClickable ? () => ({}) : props.onClick}
        onMouseLeave={() => setColor(getColorByCounter())}
      >
        <div className="code">
          {props.children}
        </div>
      </div>
    </div>
  )
}