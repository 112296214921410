import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(120,255,0)',
    },
  },
  typography: {
    allVariants: {
      color: '#4b4b4b',
    },
    fontFamily: [
      'Montserrat',
      'Inter',
    ].join(','),
  },
})

export default theme