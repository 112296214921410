import React from 'react'
import { Dialog } from '@mui/material'
import { makeStyles } from '@mui/styles'

type Props = {
  children: JSX.Element,
  open: boolean,
  close: () => void,
}

const useStyles = makeStyles(() => ({
  bg: {
    '& .MuiDialog-paper': {
      maxWidth: '690px',
      borderRadius: '10px',
      '@media (max-width: 700px)': {
        margin: 0,
        width: '100%',
        minHeight: '100vh',
      },
    },
  },
  content: {
    padding: '2rem 3rem',
    backgroundColor: 'whitesmoke',
    borderRadius: '10px',
    filter: 'drop-shadow(0px 0px 3px black)',
    position: 'relative',
    '@media (max-width: 700px)': {
      margin: 0,
      width: '100%',
      minHeight: '100vh',
      borderRadius: 0,
    },
  },
  close: {
    width: 40,
    height: 40,
    margin: '0.5rem',
    backgroundColor: 'transparent',
    border: 'none',
    color: 'tomato',
    fontSize: '2rem',
    fontWeight: 900,
    position: 'fixed',
    top: 0,
    right: 0,
    zIndex: 1,
    cursor: 'pointer',
    transition: 'transform 1s',
    '&:hover': {
      transform: 'rotate(360deg)',
    },
  }
}), { name: 'Modal' })

const Modal = ({ children, open = false, close = () => null }: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <Dialog open={open} onClose={close} className={classes.bg} fullWidth maxWidth='xl'>
      <div className={classes.content}>
        <button className={classes.close} onClick={close}>&times;</button>
        {children}
      </div>
    </Dialog>
  )
}

export default Modal
