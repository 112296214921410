/* eslint-disable max-len */
import React, { useCallback, useState } from 'react'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import Button from '../../components/generic/Button'
import logo from '../../assets/logo512.png'
import blank from '../../assets/blank.png'
import { useMousePosition } from '../../hooks/useMousePosition'
import ApproveSvg from '../../components/generic/AprroveSvg'
import background from '../../assets/background.svg'
import useWindowResize from '../../hooks/useWindowResize'
import Modal from '../../components/Modal'
import ContactUs from '../../components/Forms/ContactUs'
// import { DASHBOARD_URL } from '../../consts'

type Dimensions = { x: number, y: number }

const divideBy = 50

const getValue = (first: Dimensions, second: Dimensions, dim: 'x' | 'y'): number => Math.floor((first[dim] - second[dim]) / divideBy)

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '3%',
    padding: 20,
  },
  backgroundMain: {
    background: `url(${background})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: -1,
    top: 0,
    left: 0,
  },
  container: {
    marginBottom: '9vh',
    '@media (max-width: 700px)': {
      marginBottom: '6vh',
    }
  },
  heroWrapper: {
    display: 'flex',
    margin: '0 auto',
    maxWidth: 1300,
    '@media (max-width: 1150px)': {
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
    '@media (max-width: 700px)': {
      flexDirection: 'column',
    }
  },
  heroContent: {
    '@media (min-width: 1150px)': {
      width: '50%',
    },
    marginTop: '9vh',
    animation: '$fade-in 0.6s ease-in',
  },
  headerOne: {
    fontSize: 50,
    color: '#111',
    textAlign: 'left',
    '@media (max-width: 700px)': {
      fontSize: 33,
      textAlign: 'center',
    },
  },
  paragraph: {
    maxWidth: 485,
    margin: 10,
    fontSize: 19,
    textAlign: 'left',
    '@media (max-width: 700px)': {
      fontSize: 16,
      maxWidth: 414,
      textAlign: 'center',
    },
  },
  buttonRoot: {
    marginTop: '5vh',
    textAlign: 'left',
    '@media (max-width: 700px)': {
      textAlign: 'center',
    },
  },
  button: {
    fontSize: 19,
    height: 50,
    width: 164,
    '@media (max-width: 700px)': {
      fontSize: 17,
      height: 45,
      width: 144,
    },
  },
  heroImage: {
    width: '50%',
    position: 'relative',
  },
  image: {
    width: '100%',
    top: -20,
    left: 100,
  },
  moving: {
    position: 'absolute',
    transition: 'transform 3s',
    transitionTimingFunction: 'ease',
  },
  background: {
    borderRadius: '100%',
    backgroundImage: 'linear-gradient(298deg, #41ca94, #9fc5e84a)',
    left: 150,
  },
  feature: {
    width: 220,
    opacity: 0,
    animation: '$fade-in-up 0.6s ease-in',
    animationFillMode: 'forwards',
    '@media (max-width: 700px)': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: 170,
    },
  },
  mainText: {
    fontWeight: 700,
    margin: 0,
    fontSize: 20,
    marginLeft: 16,
    '@media (max-width: 700px)': {
      marginLeft: 0,
    },
  },
  secondaryText: {
    fontSize: 15,
    marginLeft: 16,
    marginTop: 12,
    color: '#16ca94',
    '@media (max-width: 700px)': {
      textAlign: 'center',
      marginLeft: 0,
    },
  },
  imgAsFeature: {
    width: 290,
    height: 290,
  },
  '@keyframes fade-in-up': {
    from: {
      transform: 'translate3d(0,40px,0)'
    },
    to: {
      opacity: 1,
      transform: 'translate3d(0,0,0)',
    },
  },
  '@keyframes fade-in': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
}), { name: 'Landing' })

export default function Landing(): JSX.Element {
  const classes = useStyles()
  const position = useMousePosition()
  const windowSize = useWindowResize()
  const [dimensions, setDimensions] = useState({ x: 0, y: 0, width: window.innerWidth / 2 })
  const [modal, setModal] = useState<boolean>(false)

  const callBackRef = useCallback((domNode) => {
    if (domNode) {
      const a = domNode.getBoundingClientRect()
      const {
        width: widthBounding,
      } = a
      const elementPos = { x: window.innerWidth / 2, y: window.innerHeight / 2, width: widthBounding }

      setDimensions(elementPos)
    }
  }, [windowSize.width])

  return (
    <div className={classes.root}>
      <div className={classes.backgroundMain} />
      <div className={classes.container}>
        <div className={classes.heroWrapper}>
          <div className={classes.heroContent}>
            <h1 className={classes.headerOne}>Banking made simple</h1>
            <p className={classes.paragraph} >
              {'The easiest way for people to connect their financial accounts to an app. Start building with Open-Finance\'s APIs.'}
            </p>
            <Button
              invert
              overwriteRoot={classes.buttonRoot}
              overwriteClassName={classes.button}
              // onClick={() => window.location.assign(DASHBOARD_URL)}
              onClick={() => setModal(true)}
            >
              Get Started
            </Button>
            {modal && (
              <Modal open={modal} close={() => setModal(false)}>
                <ContactUs successCallback={() => setModal(false)} />
              </Modal>
            )}
          </div>
          {windowSize.width > 1150 && (
            <div ref={callBackRef} className={classes.heroImage}>
              <img
                className={classNames(classes.moving, classes.background)}
                src={blank}
                alt="background"
                style={{
                  height: dimensions.width * 0.95,
                  width: dimensions.width * 0.95,
                  transform: `translate(${getValue(dimensions, position, 'x')}px, ${getValue(dimensions, position, 'y')}px)`
                }}
              />
              <img
                className={classNames(classes.moving, classes.image)}
                src={logo}
                alt="logo"
                style={{
                  transform: `translate3d(${getValue(position, dimensions, 'x')}px, ${getValue(position, dimensions, 'y')}px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(15deg) skew(0deg, 0deg)`
                }}
              />
            </div>)
          }
        </div>
      </div>
      <div className={classes.heroWrapper}>
        {windowSize.width < 700 && (
          <div ref={callBackRef} className={classNames(classes.feature, classes.imgAsFeature)}>
            <img
              className={classNames(classes.moving, classes.background)}
              src={blank}
              alt="background"
              style={{
                height: dimensions.width * 0.95,
                width: dimensions.width * 0.95,
                transform: `translate(${getValue(dimensions, position, 'x')}px, ${getValue(dimensions, position, 'y')}px)`,
                left: 0,
                top: 0,
              }}
            />
            <img
              className={classNames(classes.moving, classes.image)}
              src={logo}
              alt="logo"
              style={{
                left: 0,
                top: 0,
                transform: `translate3d(${getValue(position, dimensions, 'x')}px, ${getValue(position, dimensions, 'y')}px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(15deg) skew(0deg, 0deg)`
              }}
            />
          </div>)
        }
        <div className={classes.feature}>
          <ApproveSvg />
          <p className={classes.mainText} >Quick setup</p>
          <p className={classes.secondaryText} >Connect to every bank in 15 minutes.</p>
        </div>
        <div className={classes.feature} style={{ animationDelay: '300ms' }} >
          <ApproveSvg />
          <p className={classes.mainText} >Easy to use</p>
          <p className={classes.secondaryText} >Simple yet powerful api for developers.</p>
        </div>
        <div className={classes.feature} style={{ animationDelay: '600ms' }} >
          <ApproveSvg />
          <p className={classes.mainText} >For everyone</p>
          <p className={classes.secondaryText} >For businesses and ideas of all sizes.</p>
        </div>
      </div>
    </div>
  )
}