import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import NavBar from './components/Navbar'
import Landing from './views/Landing/Landing'

const MainRouter = (): JSX.Element => (
  <Router>
    <div>
      <NavBar />
      <Switch>
        <Route path="/" exact component={Landing} />
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
    </div>
  </Router>
)

export default MainRouter
