const {
  REACT_APP_DASHBOARD_URL: DASHBOARD_URL = '/',
  REACT_APP_DOCS_URL: DOCS_URL = '/',
  REACT_APP_EMAILJS_SERVICE_ID: EMAILJS_SERVICE_ID = 'None',
  REACT_APP_EMAILJS_TEMPLATE_ID: EMAILJS_TEMPLATE_ID = 'None',
  REACT_APP_EMAILJS_USER_ID: EMAILJS_USER_ID = 'None',
} = process.env

export {
  DASHBOARD_URL,
  DOCS_URL,
  EMAILJS_SERVICE_ID,
  EMAILJS_TEMPLATE_ID,
  EMAILJS_USER_ID,
}
