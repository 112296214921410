// this function validates the email using a regular expression,
const validateEmail = (str: string): boolean => {
  // verify data (using regular expressions)
  // eslint-disable-next-line
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isVerified = emailRegex.test(str)

  if (isVerified) {
    return true
  }

  return false
}

export {
  validateEmail,
}
