import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import {
  Link
} from 'react-router-dom'
import logo from '../assets/logo.png'
import { DASHBOARD_URL, DOCS_URL } from '../consts'

const COLORS = ['#16ca94', '#18ca92', '#1274e3', '#6e32e5', '#f3455c']
let counter = 0
const getColorByCounter = (): string => {
  counter += 1

  return COLORS[counter % COLORS.length]
}

const useStyles = makeStyles(({ spacing }: Theme) => ({
  navbar: {
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '1680px',
    zIndex: 12,
    display: 'flex',
    '@media (max-width: 1150px)': {
      borderBottom: '1px solid #e3e3e3',
    },
  },
  logoWrapper: {
    alignItems: 'center',
    display: 'flex',
  },
  logo: {
    width: 80,
    aspectRatio: 'auto 126 / 48',
    '@media (max-width: 1150px)': {
      paddingLeft: spacing(),
    },
  },
  menuButton: {
    color: '#111',
    fontWeight: 600,
    textDecoration: 'none',
    transitionDuration: '200ms',
    transitionTimingFunction: 'ease-out',
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingRight: spacing(3),
    '@media (max-width: 1150px)': {
      paddingRight: spacing(2),
    },
  },
  buttons: {
    cursor: 'pointer',
    textDecoration: 'none',
    transitionDuration: '0.1s',
    transitionTimingFunction: 'ease-out',
    border: '1px solid #111111',
    outlineColor: '#0868b8',
    borderRadius: '2px',
    height: 50,
    padding: '1.2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 150,
    '@media (max-width: 1150px)': {
      paddingRight: spacing(3),
      minWidth: 115,
      height: 45,
    },
    '&:hover': {
      borderColor: ({ color }: { color: string }) => color,
      '& a': {
        color: ({ color }) => color,
      }
    },
  },
}), { name: 'NavBar' })

export default function NavBar(): JSX.Element {
  const [color, setColor] = useState(getColorByCounter())
  const classes = useStyles({ color })

  return (
    <div className={classes.navbar}>
      {/* logo section */}
      <img className={classes.logo} src={logo} alt="logo" />

      <div style={{ display: 'flex' }}>
        <div
          className={classes.buttonWrapper}
          onClick={() => window.location.replace(DOCS_URL)}
        >
          <Link className={classes.menuButton} to={DOCS_URL}>Docs</Link>
        </div>
        <div
          className={classes.buttonWrapper}
          onClick={() => window.location.replace(DASHBOARD_URL)}
          onMouseLeave={() => setColor(getColorByCounter())}
        >
          <div className={classes.buttons}>
            <Link className={classes.menuButton} to={DASHBOARD_URL}>Login</Link>
          </div>
        </div>
      </div>
    </div>
  )
}
