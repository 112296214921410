import React, { ChangeEvent, useState } from 'react'
import { toast } from 'react-toastify'
import * as emailjs from 'emailjs-com'
import { makeStyles } from '@mui/styles'
import { TextareaAutosize, TextField } from '@mui/material'
import Button from '../generic/Button'
import { validateEmail } from '../../functions/validators'
import { EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, EMAILJS_USER_ID } from '../../consts'

type Props = { successCallback?: () => void }
type FormState = { email: string; name: string; message: string; }
type ErrorsState = { field: string; label: string; }[]

const useStyles = makeStyles(() => ({
  title: {
    marginBottom: '3rem',
    textAlign: 'center',
  },
  form: {
    margin: '0 3rem 2rem 3rem',
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    margin: '0.4rem 0 !important',
  },
  textArea: {
    padding: '1rem 0.8rem',
    margin: '0.4rem 0',
    backgroundColor: 'transparent',
    borderRadius: '4px',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    fontFamily: 'inherit',
    fontSize: '1rem',
    outlineColor: '#0288d1',
  },
  buttonRoot: {
    textAlign: 'center',
  },
  button: {
    fontSize: 16,
    height: 50,
    width: '100%',
  },
  error: {
    paddingLeft: '0.5rem',
    margin: '0 0 0.5rem 0',
    color: 'red',
    fontSize: '0.85rem',
    fontWeight: 600,
  },
}), { name: 'Contact' })

const ContactUs = ({ successCallback = () => null }: Props): JSX.Element => {
  const classes = useStyles()
  const [formData, setFormData] = useState<FormState>({ email: '', name: '', message: '' })
  const [errors, setErrors] = useState<ErrorsState>([])
  const [isSending, setIsSending] = useState<boolean>(false)

  const validateForm = (data: FormState, activeField?: string): boolean => {
    const { email, name, message } = data
    const errorsToSet = [] as ErrorsState

    if (!email || !name || !message ) {
      if ((!message && !activeField) || (!message && activeField === 'message')) {
        errorsToSet.push({ field: 'message', label: 'Message is required' })
      }
      
      if ((!name && !activeField) || (!name && activeField === 'name')) {
        errorsToSet.push({ field: 'name', label: 'Name is required' })
      }

      if ((!email && !activeField) || (!email && activeField === 'email')) {
        errorsToSet.push({ field: 'email', label: 'Email is required' })
      } else if ((!activeField && !validateEmail(email)) || (activeField === 'email' && !validateEmail(email))) {
        errorsToSet.push({ field: 'email', label: 'Email is not valid' })
      }

      setErrors(errorsToSet)
      
      return false
    } else if (email) {
      const validEmail = validateEmail(email)

      if (!validEmail) {
        errorsToSet.push({ field: 'email', label: 'Email is not valid' })

        setErrors(errorsToSet)
      
        return false
      }
    }

    setErrors(errorsToSet)
    
    return true
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const { name, value } = e.target

    setFormData((prev) => {
      const payload = { ...prev, [name]: value }

      validateForm(payload, name)

      return payload
    })
  }

  const handleSubmit = async (): Promise<void> => {
    const isValid = validateForm(formData)
    
    if (isValid) {
      try {  
        setIsSending(true)
        toast('Submitting form...', { type: 'info' })
        
        await emailjs.send(EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, { ...formData }, EMAILJS_USER_ID)
        
        toast('Form submitted', { type: 'success' })
        successCallback()
      } catch (error) {
        console.error(error)
        
        setIsSending(false)
        toast('An error occured', { type: 'error' })
      }
    } else {
      toast('Bad request', { type: 'error' })
    }
  }

  return (
    <div>
      <h2 className={classes.title}>Contact Us</h2>

      <form className={classes.form} onSubmit={(e) => {
        e.preventDefault()
        if (!isSending) handleSubmit()
      }}
      >
        <TextField 
          placeholder='Your full name'
          type='text'
          name='name'
          value={formData['name']}
          onChange={handleChange}
          color='info'
          className={classes.input}
        />

        {errors.filter((err) => err.field === 'name').map((err) => (
          <p className={classes.error} key={err.label}>{err.label}</p>
        ))}

        <TextField 
          placeholder='Your email'
          type='email'
          name='email'
          value={formData['email']}
          onChange={handleChange}
          color='info'
          className={classes.input}
        />

        {errors.filter((err) => err.field === 'email').map((err) => (
          <p className={classes.error} key={err.label}>{err.label}</p>
        ))}

        <TextareaAutosize
          placeholder='Your message'
          name='message'
          value={formData['message']}
          onChange={handleChange}
          className={classes.textArea}
        />

        {errors.filter((err) => err.field === 'message').map((err) => (
          <p className={classes.error} key={err.label}>{err.label}</p>
        ))}

        <Button
          invert
          overwriteRoot={classes.buttonRoot}
          overwriteClassName={classes.button}
          onClick={isSending ? () => null : handleSubmit}
        >
          Submit
        </Button>
      </form>
    </div>
  )
}

export default ContactUs
